import React from 'react';
import './styles/ComingSoon.css'; // We'll create this CSS file next

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">Coming Soon</h1>
      <p className="coming-soon-text">We are working hard to bring you something amazing!</p>
    </div>
  );
};

export default ComingSoon;
